import React, {useState} from 'react'
import SidebarX from './SidebarX'
import skull2 from '../imgs/skull2.png'

let Menu = ({screenWidth, isMobile, showSideBar, toggleSidebar}) => {
  return (
    <div className={'top noselect'} style={{width: screenWidth}}>
      <div className={'topMid'} style={{width: isMobile ? 320 : 640}}>
        <div className={'flexRow lato300'} style={{alignItems: 'center', marginBottom: isMobile ? 9 : 0}}>
          <img src={skull2} style={{height: 30, widht: 30, opacity: 0.6}} />
          <div style={{marginLeft: 4}}>kaizoku</div>
        </div>
        <div style={{marginBottom: isMobile ? 10 : 4}}>
          <SidebarX showSideBar={showSideBar} toggleSidebar={toggleSidebar} />
        </div>
      </div>
    </div>
  )
}
export default Menu
