import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {withCookies, Cookies} from 'react-cookie'

import Axios from 'axios'
import Top from './side/Top'
import Main from './home/Main'
import Learn from './home/Learn'
import Loop from './home/Loop'

import Sidebar from './side/Sidebar'
import Modal from './side/Modal'

class Home extends Component {
  constructor(props) {
    super(props)
    let dir = this.props?.match?.params?.dir || ''
    let {cookies} = props
    let cookiesAll = cookies.getAll()
    let cookieArr = Object.values(cookiesAll)

    this.state = {
      dir,
    }
  }

  componentDidMount() {
    console.log('☀️')
  }

  toggleSidebar = () => {
    this.setState({showSideBar: !this.state.showSideBar})
  }

  toggleModal = num => {
    let modalShow = num
    if (this.state.modalShow) {
      modalShow = false
    }
    this.setState({modalShow})
  }

  render() {
    let {screenHeight, screenWidth, isMobile, loopNum} = this.props
    let {showSideBar, modalShow, loaded, dir} = this.state

    if (dir == 'loop') {
      return <Loop loopNum={loopNum} />
    }

    return (
      <div style={{position: 'relative'}}>
        <div className={'page'}>
          <Sidebar
            showSideBar={showSideBar}
            toggleSidebar={this.toggleSidebar}
            screenHeight={screenHeight}
            logout={() => {}}
            isMobile={isMobile}
          />
          <Top screenWidth={screenWidth} showSideBar={showSideBar} toggleSidebar={this.toggleSidebar} isMobile={isMobile} />
          {dir == 'learn' ? (
            <Learn />
          ) : (
            <Main isMobile={isMobile} toggleModal={this.toggleModal} modalShow={modalShow} toggleModal={this.toggleModal} />
          )}
        </div>
        <Modal isMobile={isMobile} modalShow={modalShow} toggleModal={this.toggleModal} screenWidth={screenWidth} />
      </div>
    )
  }
}

export default withCookies(withRouter(Home))
