import React, {useState, useEffect} from 'react'
import {getLoopArr} from '../util/helpers'

let Loop = ({loopNum}) => {
  let data = getLoopArr()

  return (
    <div id={'copyMe'}>
      <div>{data[loopNum]}</div>
    </div>
  )
}
export default Loop
