import React, {useState, useEffect} from 'react'
import Pfp from './Pfp'

let Main = ({isMobile, toggleModal, modalShow}) => {
  let allUsers = []
  let showUsers = allUsers?.allUsers?.map((a, k) => (
    <div key={k}>
      <pre>{JSON.stringify(a, null, 2)}</pre>
    </div>
  ))

  console.log('isMobile', isMobile)

  let pfpArr = [
    {id: 1},
    {id: 2},
    {id: 3},
    {id: 4},
    {id: 5},
    {id: 6},
    {id: 7},
    {id: 8},
    {id: 9},
    {id: 10},
    {id: 11},
    {id: 12},
    {id: 13},
    {id: 14},
    {id: 15},
    {id: 16},
    {id: 17},
    {id: 18},
    {id: 19},
    {id: 20},
    {id: 21},
    {id: 22},
    {id: 23},
    {id: 24},
  ]

  let showPfp = pfpArr.map(a => {
    return <Pfp onClick={() => toggleModal(a.id)} id={a.id} />
  })

  let mainClass = modalShow ? 'mainList lato300 blur' : 'mainList lato300'

  return (
    <div className={mainClass} style={{width: isMobile ? 330 : 670, marginTop: isMobile ? 96 : 106}}>
      <div className={'flexRow'} style={{flexWrap: 'wrap', justifyContent: 'space-between'}}>
        {showPfp}
      </div>
    </div>
  )
}
export default Main
