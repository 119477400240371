import React, {useCallback} from 'react'

let Modal = ({isMobile, modalShow, toggleModal, screenWidth}) => {
  let heightCalc = 'calc(100vh - 700px)'
  if (isMobile) {
    heightCalc = 'calc(100vh - 540px)'
  }
  let barWidth = screenWidth

  let openProfile = () => {
    console.log('openProfile')
  }

  let bigImgSize = isMobile ? screenWidth - 40 : 400

  let menu = (
    <div
      onClick={toggleModal}
      className={'flexCol lato300'}
      style={{
        alignItems: 'center',
        backgroundColor: '#666',
        width: barWidth,
        height: isMobile ? 540 : 700,
        border: '0px solid #444',
        borderTopWidth: 1.5,
      }}>
      <div
        className={'flexCenter'}
        style={{
          height: bigImgSize,
          width: bigImgSize,
          border: '1px solid #aaa',
          marginTop: 30,
          borderRadius: 20,
          overflow: 'hidden',
          backgroundColor: '#aaa',
        }}>
        <img
          className={'boxShadowW'}
          src={`https://kaizoku.ai/pfp/${modalShow}.jpeg`}
          style={{height: bigImgSize, width: bigImgSize, borderRadius: 20, backgroundColor: '#aaa'}}
        />
      </div>
      <div className={'flexRow'} style={{justifyContent: 'space-between', width: bigImgSize}}>
        <div className={'flexCol'} style={{fontSize: 20, color: '#fbf7f5', marginTop: 12}}>
          <div style={{marginLeft: 4, fontSize: 21}}>kaizoku</div>
          <div className={'flexRow'} style={{marginTop: -9}}>
            <div className={'lora500'} style={{marginLeft: 4, fontSize: 32, marginTop: 6}}>{`#`}</div>
            <div className={'lora500'} style={{fontSize: 50}}>{`324`}</div>
          </div>
        </div>
        <div className={'flexCol'} style={{alignItems: 'flex-end', marginTop: 12, color: '#fbf7f5', fontStyle: 'italic'}}>
          <div className={'lato300'} style={{fontSize: 18}}>{`--`}</div>
          <div className={'lato300'} style={{fontSize: 20}}>{`style: witch`}</div>
          <div className={'lato300'} style={{fontSize: 20}}>{`hue: orange`}</div>
          <div className={'lato300'} style={{fontSize: 20}}>{`prop: ghosts`}</div>
          <div className={'lato300'} style={{fontSize: 20}}>{`weather: snow`}</div>
          <div className={'lato300'} style={{fontSize: 20}}>{`mood: happy`}</div>
        </div>
      </div>
    </div>
  )

  return (
    <div
      className={'lato300 flexCol noselect'}
      style={{
        position: 'fixed',
        left: 0,
        top: 0,
        display: 'flex',
        flex: 1,
        height: '100vh',
        transform: modalShow ? 'translateY(0)' : `translateY(100vh)`,
        transition: 'transform 450ms ease',
        width: barWidth,
        zIndex: modalShow ? 150 : -2,
      }}>
      <div
        onClick={toggleModal}
        style={{
          width: barWidth,
          height: heightCalc,
        }}
      />
      {menu}
    </div>
  )
}
export default Modal
