import React, {useState} from 'react'
import Axios from 'axios'

function importAllImages(r) {
  return r.keys().map(r)
}

let Learn = ({}) => {
  let [images, setImages] = useState(importAllImages(require.context('../imgs/learn', true, /\.(png|jpe?g|svg)$/)))
  let [hide, setHide] = useState([99])

  /*
  let removeFromArr = name => {
    let newImages = images.filter(a => a.default != name)
    setImages(newImages)
  }
  */
  let hideFromArr = (name, k) => {
    let newHide = hide.concat([k])
    setHide(newHide)
  }

  const moveImg = async (imageName, k) => {
    let urlEncode = encodeURIComponent(imageName)
    hideFromArr(imageName, k)
    try {
      const response = await Axios.post(`http://localhost:3001/move-image/${urlEncode}`)
    } catch (error) {
      console.error('Error deleting image:', error)
    }
  }

  const deleteImg = async (imageName, k) => {
    let urlEncode = encodeURIComponent(imageName)
    hideFromArr(imageName, k)
    try {
      const response = await Axios.delete(`http://localhost:3001/delete-image/${urlEncode}`)
    } catch (error) {
      console.error('Error moving image:', error)
    }
  }

  let showImgs = images.map((image, k) => {
    if (hide.includes(k)) return null
    const imageName = image.default
    return (
      <div key={k} className={'flexRow'} style={{alignItems: 'center'}}>
        <div
          className={'fixRed'}
          onClick={() => deleteImg(imageName, k)}
          style={{
            height: 100,
            width: 100,
            marginRight: 30,
            border: '1px solid red',
            borderRadius: 10,
            cursor: 'pointer',
          }}
        />
        <div style={{marginBottom: 20}}>
          <img key={k} style={{height: 500, width: 500}} src={image.default} />
          <div style={{width: 300, overflow: 'hidden'}}>{imageName}</div>
        </div>
        <div
          className={'fixGreen'}
          onClick={() => moveImg(imageName, k)}
          style={{
            height: 100,
            width: 100,
            marginLeft: 30,
            border: '1px solid green',
            borderRadius: 10,
            cursor: 'pointer',
          }}
        />
      </div>
    )
  })
  return (
    <div className={'flexCol lato300'} style={{alignItems: 'center', alignSelf: 'stretch', marginTop: 100}}>
      <div>learn</div>
      {showImgs}
    </div>
  )
}
export default Learn
