import React, { useEffect, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";

function SidebarX(props) {
  let { showSideBar, toggleSidebar } = props;

  return (
    <button
      className={"settingsButton"}
      style={{ marginBottom: 5 }}
      onClick={toggleSidebar}
    >
      {showSideBar ? (
        <div>
          <div
            className={"timesLeft"}
            style={{
              height: 1.5,
              width: 25,
              backgroundColor: "#999",
              marginBottom: 4,
              marginTop: 6,
              marginRight: 1,
            }}
          />
          <div
            className={"timesRight"}
            style={{
              height: 1.5,
              width: 25,
              backgroundColor: "#999",
              marginBottom: 4,
              marginTop: -6,
              marginRight: 1,
            }}
          />
        </div>
      ) : (
        <div>
          <div
            style={{
              height: 1.5,
              width: 20,
              backgroundColor: "#999",
              marginBottom: 4,
              marginRight: 1,
            }}
          />
          <div
            style={{
              height: 1.5,
              width: 20,
              backgroundColor: "#999",
              marginBottom: 4,
              marginRight: 1,
            }}
          />
          <div
            style={{
              height: 1.5,
              width: 20,
              backgroundColor: "#999",
              marginRight: 1,
            }}
          />
        </div>
      )}
    </button>
  );
}
export default SidebarX;
