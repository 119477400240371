import React, {useState, useEffect} from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {BrowserRouter as Router, Link, useLocation, useHistory, Route} from 'react-router-dom'
import {CookiesProvider} from 'react-cookie'

import Home from './Home'
import './styles.css'

function useQuery() {
  const {search} = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

function HomeWithParams() {
  let query = useQuery()
  let loopNum = query.get('loopNum')

  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768

  return (
    <CookiesProvider>
      <Home isMobile={isMobile} screenHeight={height} screenWidth={width} loopNum={loopNum} />
    </CookiesProvider>
  )
}

const App = () => (
  <Router>
    <Route path="/:dir">
      <HomeWithParams />
    </Route>
    <Route exact path="/">
      <HomeWithParams />
    </Route>
  </Router>
)

/*
<Route path="/uploadImg">
  <UploadImg />
</Route>
*/

ReactDOM.render(<App />, document.getElementById('app'))

module.hot.accept()
