import React, {useState, useEffect} from 'react'

let Pfp = ({onClick, id}) => {
  let [loaded, setLoaded] = useState(false)
  let classTxt = loaded ? ' pfpFade' : ''
  return (
    <div key={id} onClick={onClick} className={'pfpBox boxShadow noselect' + classTxt}>
      <img
        src={`https://kaizoku.ai/pfp/${id}.jpeg`}
        onLoad={() => setLoaded(true)}
        style={{height: 160, width: 160, opacity: loaded ? 1 : 0}}
      />
    </div>
  )
}

export default Pfp
